import { Link } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { CgMenu, CgCloseO } from 'react-icons/cg';
import gsap from 'gsap';

export default function MobileNavBar() {
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  let navigation = useRef();

  const tl = gsap.timeline();

  // useEffect(() => {
  //   tl.from(navigation, {
  //     y: -16,
  //     opacity: 0,
  //     duration: 1.4,
  //     ease: 'power3.inOut',
  //   });
  // }, []);

  const openNav = (
    <CgMenu className='open' size='30px' onClick={() => setOpen(!open)} />
  );

  const closeNav = (
    <CgCloseO className='close' size='30px' onClick={() => setOpen(!open)} />
  );

  const closeMobileMenu = () => setOpen(false);

  return (
    <div className='mobile_nav_wrapper'>
      <nav className='mobile'>
        <div className='mobile_nav_head'>
          <img src='/Logo.png' alt='Logo' className='mobile_nav_logo' />
          {!open && openNav}
        </div>
        {open && (
          <>
            <ul>
              <div className='mobile-menu_logo'>
                <img src='/Logo.png' alt='Logo' />
                <div className='close'>{closeNav}</div>
              </div>
              <Link to='/' onClick={() => isOpen && closeMobileMenu}>
                HOME
              </Link>
              <Link
                to='/Projects/Project'
                onClick={() => isOpen && closeMobileMenu}>
                WORKS
              </Link>
              <Link
                to='/Blog/blogPost'
                onClick={() => isOpen && closeMobileMenu}>
                BLOGS
              </Link>
              <Link
                to='/Career/Career'
                onClick={() => isOpen && closeMobileMenu}>
                CAREERS
              </Link>
              <Link to='/About' onClick={() => isOpen && closeMobileMenu}>
                ABOUT
              </Link>
              <Link to='/Contact' onClick={() => isOpen && closeMobileMenu}>
                <button>CONTACT US</button>
              </Link>
            </ul>
          </>
        )}
      </nav>
    </div>
  );
}
